import golocker from "@images/common/goLocker-logo.png"
import lulu from "@images/common/lulu.png"

export const FEEDBACK_SECTION_ITEMS = [
  {
    member: "Ross Ducat",
    position: "Founder & CEO, Lulu Light",
    logoSrc: lulu,
    feedbackText: "Their speed and knowledge were impressive",
  },
  {
    member: "Sherin Davis",
    position: "Chief Product Officer, GoLocker",
    logoSrc: golocker,
    feedbackText: "Overall, they are great partners",
  },
]

export const SECOND_CATCH_UP_SECTION_TITLE = "Get in touch"
export const SECOND_CATCH_UP_SECTION_DESCRIPTION = "Complete contact form, and we will contact you within 24 hours"
