import React, { useCallback, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import arrowDown from "@images/common/ic-arrow-down.svg";
import useWindowDimensions from "@hooks/useWindowDimensions";
import { MOBILE_MIN_WIDTH, TABLET_MAX_WIDTH } from "@constants";
import { ReactSVG } from "react-svg";

const DropDownItem = ({ dropDownOption }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const containerRef = useRef(null);
    const { isDesktop } = useWindowDimensions();

    useEffect(() => {
        if (!containerRef?.current) return;

        const handleClick = e => {
            if (isHovered) {
                setIsHovered(false);
            }
        };

        document.addEventListener("touchend", handleClick);

        return () => {
            document.removeEventListener("touchend", handleClick);
        };
    }, [isOpen]);

    const updateOpenState = useCallback(() => {
        let isCurrentlyOpen;
        setIsOpen(prevState => {
            isCurrentlyOpen = !prevState;

            return !prevState;
        });

        if (isCurrentlyOpen) {
            setIsHovered(true);
        }
    }, []);

    return (
        <DropDownItemContainer
            onClick={updateOpenState}
            isDesktop={isDesktop}
            isHovered={isHovered}
            ref={containerRef}
        >
            <ShortInfoContainer>
                <Title isDesktop={isDesktop} isHovered={isHovered}>
                    {dropDownOption.title}
                </Title>
                <ArrowIconContainer isOpen={isOpen}>
                    <ReactSVG src={arrowDown} alt="arrow" />
                </ArrowIconContainer>
            </ShortInfoContainer>
            <DescriptionContainer isOpen={isOpen}>
                <DropDownDescription>
                    {dropDownOption.description}
                </DropDownDescription>
            </DescriptionContainer>
        </DropDownItemContainer>
    );
};

const DropDownItemContainer = styled.div`
    padding: 1.5rem 10.1875rem;
    border-bottom: 0.0625rem solid #3a1a3c;
    border-top: 0.0625rem solid transparent;
    transition: 0.5s;
    transform-origin: bottom;

    ${props =>
        props.isDesktop
            ? css`
                  &:hover {
                      padding-top: 1.5rem;
                      background: rgba(196, 177, 128, 0.1);
                      border-top: 0.0625rem solid #c4b180 !important;
                      border-color: #c4b180;
                  }
              `
            : props.isHovered
            ? css`
                  padding-top: 1.5rem;
                  background: rgba(196, 177, 128, 0.1);
                  border-top: 0.0625rem solid #c4b180 !important;
                  border-color: #c4b180;
              `
            : null}

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        padding: 1rem 6.25rem;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        padding: 1rem 1.5rem;
    }
`;

const ShortInfoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Title = styled.h3`
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 2.5rem;
    letter-spacing: 0.02em;
    color: rgba(255, 255, 255, 0.6);
    margin: 0;
    ${props =>
        props.isDesktop
            ? css`
                  ${DropDownItemContainer}:hover && {
                      color: #c4b180;
                  }
              `
            : props.isHovered
            ? css`
                  color: #c4b180;
              `
            : null}

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        font-size: 1.25rem;
        line-height: 1.875rem;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        padding: 0 1.5rem;
    }
`;

const DescriptionContainer = styled.div`
    overflow: hidden;
    max-height: 0;
    transition: 400ms max-height ease-in-out;

    ${props =>
        props.isOpen &&
        css`
            max-height: 32rem;
        `}
`;

const DropDownDescription = styled.p`
    width: 95%;
    line-height: 1.625rem;
    color: var(--main-font-color);
    margin-top: 1.5rem;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        width: fit-content;
        font-size: 1.25rem;
        line-height: 1.875rem;
    }
`;

const ArrowIconContainer = styled.div`
    display: flex;
    align-items: flex-start;
    height: 0.9rem;
    width: fit-content;
    transition: all 400ms linear;
    transform: rotate(0);

    & svg {
        width: 1.25rem;
        height: 0.815rem;
    }

    ${props =>
        props.isOpen &&
        css`
            transition: all 400ms linear;
            transform: rotate(180deg);
        `}
`;

export default DropDownItem;
